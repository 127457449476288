<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-6">
        <h1>{{ `${equipment.name}` }}</h1>
      </span>
      <div class="col-6 text-right"></div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.BELONG_TO_LANIEL") }}</dt>
          <dd class="col-sm-8">
            {{ equipment.is_internal ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            {{
              equipment.status
                ? $t(
                    "COMMON.EQUIPMENT_STATUS_" + equipment.status.toUpperCase()
                  )
                : "Aucun Status"
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ equipment.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CODE") }}</dt>
          <dd class="col-sm-8">
            {{ equipment.code }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.BRAND") }}</dt>
          <dd class="col-sm-8">
            {{ equipment.brand }}
          </dd>
        </dl>
        <dl class="row" v-if="equipment.serial_number">
          <dt class="col-sm-4">{{ $t("COMMON.SERIAL_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ equipment.serial_number }}
          </dd>
        </dl>
        <dl class="row" v-if="equipment.guarantee">
          <dt class="col-sm-4">{{ $t("COMMON.GUARANTEE") }}</dt>
          <dd class="col-sm-8">
            {{ equipment.guarantee }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="equipment.organization" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_CATEGORY_EQUIPMENTS) &&
            equipment.categoryEquipment
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.CATEGORY_EQUIPMENTS") }}</dt>
          <dd class="col-sm-8">
            <category-equipment
              :categoryEquipment="equipment.categoryEquipment"
            />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
            equipment.establishment
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="equipment.establishment" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS) &&
            equipment.serviceCenter
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <serviceCenter :serviceCenter="equipment.serviceCenter" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) &&
            equipment.cafeteria
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
          <dd class="col-sm-8">
            <cafeteria :cafeteria="equipment.cafeteria" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(equipment.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(equipment.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import CategoryEquipment from "@/components/CategoryEquipment.vue";

export default {
  name: "equipment-view-global",
  components: {
    IconCheck,
    Cafeteria,
    ServiceCenter,
    Establishment,
    CategoryEquipment,
  },

  props: ["equipment"],

  data() {
    return {};
  },

  created() {},

  methods: {
    equipmentUpdated() {
      this.$emit("equipmentUpdated", true);
    },
  },

  mounted() {},

  watch: {
    equipment(equipment) {},
  },
};
</script>
