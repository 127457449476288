<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATUS')} (*)`"
          :placeholder="$t('COMMON.STATUS')"
        >
          <el-select
            name="status"
            v-model.lazy="equipment.status"
            placeholder="status"
            @change="
              (status) => {
                equipment.status = status;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(label, val) in statusesOption"
              :key="val"
              :value="val"
              :label="label"
            ></el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.status" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !hideOrganization
          "
        >
          <organization-selector
            :organization="equipment.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                equipment.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!equipment.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CATEGORY_EQUIPMENTS')} (*)`"
          :placeholder="$t('COMMON.CATEGORY_EQUIPMENTS')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
            !hideOrganization
          "
        >
          <category-equipment-selector
            :categoryEquipment="equipment.categoryEquipment?.id"
            :filterable="true"
            :showAll="false"
            @categoryEquipmentValChanged="
              (categoryEquipmentId) => {
                equipment.categoryEquipment.id = categoryEquipmentId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.serviceCenter" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="equipment.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CODE')} (*)`"
          :required="true"
          v-model="equipment.code"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.code" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.BRAND')} (*)`"
          :required="true"
          v-model="equipment.brand"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.brand" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.SERIAL_NUMBER')}`"
          v-model="equipment.serial_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.serial_number" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.GUARANTEE')}`"
          v-model="equipment.guarantee"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.guarantee" />
      </div>
    </div>

    <div class="">
      <base-input :label="`${$t('COMMON.NOTE')}`" :placeholder="$t('COMMON.NOTE')">
        <html-editor v-model="equipment.excerpt" @change="onFormChanged()"> </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>
    <div class="row">
      <div class="col">
        <base-checkbox v-model="equipment.is_internal" class="my-5">
          <span class="form-control-label"> Propriétaire Laniel</span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.is_internal" />
      </div>
    </div>
    <div>
      <base-input
        :label="`${$t('COMMON.SERVICECENTER')}`"
        :placeholder="$t('COMMON.SERVICECENTER')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !hideOrganization
        "
      >
        <service-center-selector
          :filterOrganization="equipment.organization?.id"
          :serviceCenter="equipment.serviceCenter?.id"
          :filterable="true"
          :showAll="false"
          @serviceCenterChanged="
            (serviceCenterId) => {
              equipment.serviceCenter.id = serviceCenterId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.serviceCenter" />
    </div>
    <div>
      <base-input
        :label="`${$t('COMMON.ESTABLISHMENT')}`"
        :placeholder="$t('COMMON.ESTABLISHMENT')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !hideOrganization
        "
      >
        <establishment-selector
          :filterOrganization="equipment.organization?.id"
          :establishment="equipment.establishment?.id"
          :filterable="true"
          :filterServiceCenter="equipment.serviceCenter?.id"
          :showAll="false"
          @establishmentChanged="
            (establishmentId) => {
              equipment.establishment.id = establishmentId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.establishment" />
    </div>

    <div>
      <base-input
        :label="`${$t('COMMON.CAFETERIA')}`"
        :placeholder="$t('COMMON.CAFETERIA')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) && !hideOrganization"
      >
        <cafeteria-selector
          :filterOrganization="equipment.organization?.id"
          :cafeteria="equipment.cafeteria?.id"
          :filterEstablishment="equipment.establishment?.id"
          :filterable="true"
          :showAll="false"
          @cafeteriaChanged="
            (cafeteriaId) => {
              equipment.cafeteria.id = cafeteriaId;

              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.cafeteria" />
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          equipment.id ? $t("EQUIPMENTS.EDIT_EQUIPMENT") : $t("EQUIPMENTS.ADD_EQUIPMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { equipmentStatusesOption } from "@/constants/equipments";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import CategoryEquipmentSelector from "@/components/CategoryEquipmentSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    ServiceCenterSelector,
    HtmlEditor,
    CategoryEquipmentSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["equipmentData", "formErrors", "loading", "hideOrganization", "hideSubmit"],

  data() {
    let equipmentData = { ...this.equipmentData };
    equipmentData = this.$fillUserOrganizationData(equipmentData);
    return {
      statusesOption: equipmentStatusesOption,
      equipment: equipmentData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let equipmentData = cloneDeep(this.equipment);
      equipmentData = this.$fillUserOrganizationData(equipmentData);
      this.$emit("equipmentSubmitted", equipmentData);
    },

    resetExternalFields() {
      if (!this.is_internal) {
        this.equipment.serviceCenter = { type: "service-centers", id: null };
        this.equipment.establishment = { type: "establishments", id: null };
        this.equipment.cafeteria = { type: "cafeterias", id: null };
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    equipmentData(equipmentData) {
      if (equipmentData) {
        this.equipment = {
          ...this.equipment,
          ...cloneDeep(equipmentData),
        };
      }
    },
  },
};
</script>
