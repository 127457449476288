import { render, staticRenderFns } from "./CashRegisterReadingViewGlobal.vue?vue&type=template&id=81fdd06a&scoped=true"
import script from "./CashRegisterReadingViewGlobal.vue?vue&type=script&lang=js"
export * from "./CashRegisterReadingViewGlobal.vue?vue&type=script&lang=js"
import style0 from "./CashRegisterReadingViewGlobal.vue?vue&type=style&index=0&id=81fdd06a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81fdd06a",
  null
  
)

export default component.exports